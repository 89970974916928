<template>
  <div
    :class="{
      'join-sign': isZdd,
    }"
  >
    <component
      :is="showComponent"
      :isPopup="isPopup"
      :isFormLogin="isFormLogin"
    ></component>
    <van-popup v-model="dialogShow">
      <img
        src="@/assets/imgs/banner_dialog.png"
        class="bannerDialogImg"
        style="height: 80vh"
      />
    </van-popup>
  </div>
</template>
<script>
  import { mapState } from 'vuex'
  import Api from '@/api/addSign'
  import contractSignApis from '@/api/contractSign'
  import { isMobile } from '@/utils/ua'
  import { Toast } from 'vant'
  import { resetCookies } from '@/utils/cookies'
  import addSignConfirm from './addSignConfirm.vue'
  import addSignNoConfirm from './addSignNoConfirm.vue'

  export default {
    props: ['isPopup'],
    beforeRouteEnter(to, from, next) {
      if (from.path === '/login' || from.path === '/setPassword') {
        next(vm => {
          vm.isFormLogin = true
        })
        return
      }
      next(async vm => {
        // const partnerId = to.query?.partnerId
        // if (partnerId) {
        //   const { userId, comId } = vm.$store.getters.getUserInfo
        //   const model = { partnerId, userId, comId }
        //   const { joinStatus } = await contractSignApis.checkJoinStatus(model)
        //   // 0 已加入，身份符合 ok
        //   // 1 未加入，去加入
        //   // 2 未加入，不能加入
        //   // 3 已加入，身份不符合
        //   if (joinStatus === 3 || joinStatus === 2 || joinStatus === 1) {
        //     // 如果是 身份不正确和或者是未加入的情况 都清空登录态 叫用户自己去加入
        vm.$store.dispatch('resetSession')
        resetCookies()
        //   } else if (joinStatus === 0) {
        //     // 如果是 0 则说明当前身份正确，直接自动调用加入签署，进入到详情页面，省去用户自己加入签署的逻辑
        //     const res = await Api.joinSign({ partnerId })
        //     vm.$router.replace({
        //       name: 'SignDetail',
        //       query: {
        //         businessId: res.data.businessId,
        //       },
        //     })
        //   }
        // }
      })
    },
    components: {
      addSignConfirm,
      addSignNoConfirm,
    },
    data() {
      return {
        showComponent: 'addSignNoConfirm',
        partnerId: '',
        isFormLogin: false,
        dialogShow: false,
        fileStatus: 0,
      }
    },
    computed: {
      ...mapState({
        systemInfo: state => state.system,
      }),
      isZdd() {
        return this.$store.getters['system/isZdd']
      },
    },
    created() {
      // this.$store.dispatch('resetSession')
      this.partnerId = this.$route.query.partnerId
      this.fileStatus = this.$route.query.fileStatus

      /** 特殊需求 */
      // 如果用户是从pc端打开的页面 则重定向到pc端的页面
      if (!isMobile()) {
        const pcUrl = this.systemInfo.hostPC
        const pcRoute = `/joinsign`
        window.location.href = `${pcUrl}${pcRoute}?partnerId=${this.partnerId}&fileStatus=${this.fileStatus}`
        return
      }
      /** 特殊需求 */

      this.$store.commit('sign/setPartnerId', this.partnerId)
      this.getSignInfo(this.partnerId)
    },
    methods: {
      async getSignInfo(partnerId) {
        this.toast = Toast.loading({
          message: '加载中...',
          forbidClick: true,
          loadingType: 'spinner',
        })
        let res
        if (this.fileStatus === 8 || this.fileStatus === '8') {
          res = await Api.getInvalidSignInfo({ partnerId })
        } else {
          res = await Api.getSignInfo({ partnerId })
        }
        this.$store.commit('sign/setSignMsg', res)
        if (res.remind) {
          this.dialogShow = true
        }
        // if (res.handlerTel) {
        //   this.showComponent = 'addSignConfirm'
        // }
        this.toast.clear()
      },
    },
  }
</script>
<style lang="less" scoped>
  .join-sign {
    background-image: url('../../assets/imgs/zdd/zdd_join_sign_bg.png');
    background-size: 100%;
    background-repeat: no-repeat;
    background-color: #e6efff;
    padding-top: 112px;
    .addSignNoConfirm,
    .addSignConfirm {
      margin: 0 18px;
      background: @WHITE;
      padding-bottom: 26px;
    }
    .bannerDialogImg {
      height: 80vh;
    }
  }
</style>
