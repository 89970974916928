import { get, getStatus, postStatus } from './axios'

export default {
  // 获取签署信息
  getSignInfo(params) {
    return get('/core/contractSign/getSignInfo', params)
  },
  // 加入签署
  joinSign(params) {
    return postStatus('/core/contractSign/joinSign', params)
  },
  // 切换当前身份
  updateCurrentIdentity(params) {
    return get('/user/my/updateCurrentIdentity', params)
  },
  // 获取作废签署信息
  getInvalidSignInfo(params) {
    return get('/core/contractSign/getInvalidSignInfo', params)
  },
  // 加入作废签署
  updateInvalidJoinSign(params) {
    return postStatus('/core/newContractSign/updateInvalidJoinSign', params)
  },
}
