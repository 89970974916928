<template>
  <div class="addSignConfirm">
    <div class="content">
      <div class="title">
        {{ isZdd }}
        <span>标题：{{ signMsg.title }}</span>
        <img v-if="!isZdd" :src="systemInfo.logo2" class="logo" />
      </div>
      <div class="initiator">发起人：{{ signMsg.launcher }}</div>
      <div class="initiator" v-if="signMsg.partnerComName">
        接收方：{{ signMsg.partnerComName }}
      </div>
      <div class="initiator initiator-b">
        签署截止日期：{{ signMsg.validDate | filterTime }}
      </div>
      <div class="inputPassword">请输入密码/验证码，查看或签署该文件</div>
      <div class="account">账号：{{ signMsg.handlerTel }}</div>
      <template v-if="!userInfo.userId">
        <div class="inputcode" v-if="loginType === 1">
          <van-field
            v-model="code"
            placeholder="请输入验证码"
            maxLength="6"
            oninput="value= value.replace(/[^\d]/g, '')"
          >
            <template #button v-if="loginType === 1">
              <div class="send-code" v-if="!isSend" @click="sendCode">
                获取验证码
              </div>
              <div class="send-code2" v-else>获取验证码（{{ num }}）</div>
            </template>
          </van-field>
        </div>
        <div class="inputcode" v-else>
          <van-field
            v-model="password"
            placeholder="请输入密码"
            type="password"
          />
        </div>
      </template>
    </div>
    <div class="footer" v-if="!isPopup">
      <div class="tip">
        <div class="left" v-if="loginType === 1" @click="loginMethods(2)">
          密码登录
        </div>
        <div class="left" v-if="loginType === 2" @click="loginMethods(1)">
          验证码登录
        </div>
        <div class="right">
          账号尚未开通？
          <span @click="goUrl('/register?redirect=/addSignConfirm')"
            >先去注册</span
          >
        </div>
      </div>
      <div class="btn-group">
        <template v-if="canClick">
          <div class="btn" @click="submit">下一步</div>
        </template>
        <template v-else>
          <div class="btn btn2">下一步</div>
        </template>
      </div>
    </div>
    <van-dialog
      v-model="selectIdentityDia"
      title="提示"
      show-cancel-button
      :before-close="onConfirm"
    >
      <div class="select-wrap">
        <div class="select-title">请您选择要进行签署的企业身份</div>
        <ul class="select-list">
          <li
            v-for="(item, index) in myInfo.companyInfoVoList"
            :key="index"
            @click="onSelect(item.comId)"
            :class="
              item.comId === selectComId ? 'select-item-active' : 'select-item'
            "
          >
            {{ item.comName }}
          </li>
        </ul>
      </div>
    </van-dialog>
  </div>
</template>
<script>
  import passportApis from '@/api/passport'
  import { mapState } from 'vuex'
  import { Dialog, Toast } from 'vant'
  import Api from '@/api/addSign'
  import addressApi from '@/api/address'

  export default {
    props: ['isPopup', 'isFormLogin'],
    data() {
      this.JOIN_COM = 'JOIN_COM'
      return {
        selectIdentityDia: false,
        selectComId: null,
        password: '',
        code: '',
        loginType: 1,
        num: 60,
        isSend: false,
        telephone: '',
        isJoinCurCom: false,
      }
    },
    computed: {
      ...mapState({
        userInfo: state => state.user.userInfo,
        myInfo: state => state.user.myInfo,
        partnerId: state => state.sign.partnerId,
        signMsg: state => state.sign.signMsg,
        systemInfo: state => state.system,
      }),
      isZdd() {
        return this.$store.getters['system/isZdd']
      },
      canClick() {
        if (this.userInfo.userId) return true
        if (this.password || this.code.length) return true
        return false
      },
    },
    watch: {
      'myInfo.companyInfoVoList': {
        handler(newV) {
          if (newV.length === 1) {
            this.onSelect(this.myInfo.companyInfoVoList[0].comId)
          }
        },
        immediate: true,
      },
      isFormLogin: {
        handler(newV) {
          if (newV) {
            this.addSign()
          }
        },
        immediate: true,
      },
    },
    created() {
      this.telephone = this.signMsg.handlerTel
    },
    methods: {
      goUrl(url) {
        this.$router.push(url)
      },
      loginMethods(type) {
        this.loginType = type
      },
      async submit() {
        try {
          if (!this.userInfo.userId) {
            let params = {
              account: this.telephone,
              code: this.code,
              systemId: this.systemInfo.systemId,
            }
            if (this.loginType === 2) {
              params = {
                account: this.telephone,
                password: this.password,
                systemId: this.systemInfo.systemId,
              }
            }
            await this.$store.dispatch('signlogin', params)
          }
          this.addSign()
        } catch (error) {
          Toast(error)
        }
      },
      // 验证输入的值是手机还是邮箱
      isMobile(value) {
        return /^\d+$/g.test(value)
      },
      isEmail(value) {
        return /[a-zA-Z@]/g.test(value)
      },
      sendCode() {
        const value = this.telephone.trim()
        if (this.isMobile(value)) {
          passportApis
            .getTelCode({
              telephone: value,
              type: 16,
            })
            .then(res => {
              this.setCode()
            })
        } else if (this.isEmail(value)) {
          passportApis
            .getEmailCode({
              email: value,
              type: 16,
              systemId: this.systemInfo.systemId,
            })
            .then(res => {
              this.setCode()
            })
        } else {
          Toast('请输入正确的手机号/邮箱')
        }
      },
      setCode() {
        this.isSend = true
        this.timer = setInterval(() => {
          if (!this.num) {
            clearInterval(this.timer)
            this.num = 60
            this.isSend = false
            return
          }
          this.num -= 1
        }, 1000)
      },
      onSelect(comId) {
        this.selectComId = comId
      },
      onConfirm(action, done) {
        if (action === 'cancel') {
          done()
          return
        }
        if (!this.selectComId) {
          Toast('请选择公司身份')
          done(false)
        }
        done()
        this.addSign()
      },

      async addSign() {
        this.toast = Toast.loading({
          message: '加载中...',
          forbidClick: true,
          loadingType: 'spinner',
        })
        Api.joinSign({
          partnerId: this.partnerId,
          comId: this.selectComId,
        })
          .then(async res => {
            await this.$store.dispatch('fetchUserInfo')
            const { msg, status, data } = res
            if (status === 200) {
              const { businessStatus } = data
              // 0 草稿 1未完成 2 已完成 3已作废
              if (businessStatus === 3) {
                this.$router.push({
                  path: '/signComplete',
                })
                return
              }
              this.$router.push({
                path: '/signdetail',
                query: {
                  businessId: data.businessId,
                },
              })
              return
            }
            // 请选择企业
            if (status === 207) {
              // 要选择企业的时候要先去验证当前用户是否已经加入了该企业了，如果没加入，则要展示「申请加入」按钮
              this.selectIdentityDia = true
              return
            }
            // 个人未实名
            if (status === 208) {
              // Dialog.alert({
              //   title: '提示',
              //   message: msg,
              //   confirmButtonText: '去认证',
              // }).then(() => {
              this.$router.push({
                path: 'signAuth',
                query: {
                  url: '/addSignConfirm',
                },
              })
              // })
              return
            }
            // 企业未认证
            if (status === 205) {
              // Dialog.alert({
              //   title: '提示',
              //   message: msg,
              //   confirmButtonText: '去认证',
              // }).then(() => {
              this.$router.push('/addSignError2')
              // })
              return
            }
            if (status === 209) {
              Dialog.alert({
                title: '提示',
                message: msg,
              }).then(() => {
                this.$router.push('/addSignError')
              })
              return
            }
            // 当前选择的身份不正确，清空 当前选择的 comId
            if (status === 212) {
              Dialog.alert({
                title: '提示',
                message: msg,
              }).then(() => {})

              this.selectComId = undefined
              return
            }
            Dialog.alert({
              title: '提示',
              message: msg,
            }).then(() => {})
          })
          .finally(() => {
            this.toast.clear()
            this.selectComId = null
          })
      },
    },
  }
</script>
<style lang="less" scoped>
  .addSignConfirm {
    padding-top: 20px;
    .select-wrap {
      .select-title {
        width: 227px;
        height: 22px;
        font-size: 16px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: left;
        color: #677283;
        line-height: 22px;
        margin: 0 auto;
        margin-bottom: 24px;
        text-align: center;
      }
      .select-list {
        max-height: 220px;
        overflow-y: auto;
        .select-item {
          padding: 8px;
          width: 238px;
          // height: 40px;
          border: 1px solid #e2e4ea;
          border-radius: 20px;
          margin: 0 auto;
          font-size: 16px;
          font-family: PingFangSC, PingFangSC-Medium;
          font-weight: 500;
          text-align: center;
          color: #111a34;
          margin-bottom: 16px;
        }
        .select-item-active {
          padding: 8px;
          width: 238px;
          // height: 40px;
          border: 1px solid #1676ff;
          border-radius: 20px;
          font-size: 16px;
          font-family: PingFangSC, PingFangSC-Medium;
          font-weight: 500;
          color: #1676ff;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 auto;
          &::after {
            flex-shrink: 0;
            content: '';
            width: 15px;
            height: 10px;
            margin-left: 4px;
            margin-top: 3px;
            background: url('../../assets/imgs/ios2x_路径 2@2x.png') no-repeat
              center;
            background-size: 15px 10px;
          }
          margin-bottom: 16px;
        }

        .join-com-button {
          color: @BLUE;
        }
      }
    }
    .content {
      padding: 0 24px;
      .title {
        height: 25px;
        font-size: 18px;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 500;
        text-align: left;
        color: #111a34;
        line-height: 25px;
        margin-bottom: 10px;
        width: 100%;
        padding-right: 45px;
        width: 314px;
        span {
          width: 269px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          display: block;
        }
        position: relative;
        .logo {
          width: 46px;
          height: 46px;
          position: absolute;
          top: 0px;
          right: 0px;
        }
      }
      .initiator {
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 500;
        text-align: left;
        color: #677283;
        line-height: 20px;
        margin-bottom: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .initiator-b {
        margin-bottom: 30px;
      }
      .inputPassword {
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 500;
        text-align: left;
        color: #111a34;
        line-height: 20px;
        margin-bottom: 10px;
      }
      .account {
        height: 21px;
        font-size: 15px;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 500;
        text-align: left;
        color: #677283;
        line-height: 21px;
        margin-bottom: 10px;
      }
      .inputcode {
        padding: 14px 0 12px 16px;
        display: flex;
        align-items: center;
        border: 1px solid #e2e4ea;
        border-radius: 4px;
        height: 50px;
        /deep/ .van-cell {
          padding: 0;
        }
        .send-code {
          width: 122px;
          height: 18px;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(22, 118, 255, 1);
          line-height: 18px;
          border-left: 1px solid #dfdfdf;
          text-align: center;
        }
        .send-code2 {
          width: 130px;
          height: 18px;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(109, 119, 144, 1);
          line-height: 18px;
          border-left: 1px solid #dfdfdf;
          text-align: center;
          white-space: nowrap;
        }
      }
    }
    .footer {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 375px;
      background: rgba(255, 255, 255, 1);
      .btn-group {
        box-shadow: 0 6px 20px 0 rgba(78, 128, 245, 0.16);
        padding: 6px 0 calc(env(safe-area-inset-bottom) + 10px);
      }
      .btn {
        width: 327px;
        height: 44px;
        background: rgba(22, 118, 255, 1);
        border-radius: 4px;
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(255, 255, 255, 1);
        line-height: 44px;
        text-align: center;
        margin: 0 auto;
        box-shadow: 0 -6px 10px 0 rgba(230, 234, 237, 0.3);
      }
      .btn2 {
        background: rgba(22, 118, 255, 0.2);
      }
      .tip {
        width: 327px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;

        .left {
          height: 16px;
          font-size: 12px;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          text-align: center;
          color: #6d7790;
          line-height: 16px;
        }
        .right {
          height: 16px;
          font-size: 12px;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          text-align: center;
          color: rgba(109, 119, 144, 1);
          line-height: 16px;
          span {
            color: rgba(22, 118, 255, 1);
          }
        }
      }
    }
  }
</style>
