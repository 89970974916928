<template>
  <div class="addSignNoConfirm">
    <div class="content">
      <div class="title">
        <span>标题：{{ signMsg.title }}</span>
        <img v-if="!isZdd" :src="systemInfo.logo2" class="logo" />
      </div>
      <div class="initiator">发起人：{{ signMsg.launcher }}</div>
      <div class="initiator" v-if="signMsg.partnerComName">
        接收方：{{ signMsg.partnerComName }}
      </div>
      <div class="initiator">
        签署截止日期：{{ signMsg.validDate | filterTime }}
      </div>
    </div>
    <div class="footer" v-if="!isPopup">
      <div class="btn" @click="addSign">加入签署</div>
    </div>
    <van-dialog
      v-model="selectIdentityDia"
      class="companyInfoVo-dialog"
      show-cancel-button
      :before-close="onConfirm"
    >
      <div class="select-wrap" style="padding-bottom: 20px;">
        <div class="select-title">请选择企业身份</div>
        <ul class="select-list">
          <li
            v-for="(item, index) in myInfo.companyInfoVoList"
            :key="index"
            @click="onSelect(item.comId)"
            :class="
              item.comId === selectComId ? 'select-item-active' : 'select-item'
            "
          >
            <img
              v-if="item.creditStatus === 1"
              src="@/assets/imgs/authenticationSuccess.svg"
              alt
              class="successImg"
            />
            <span
              v-else-if="item.creditStatus === 0 || item.creditStatus === 3"
              class="successImg"
              style="color:#Fe943d"
              >认证中</span
            >
            <span
              v-else-if="item.creditStatus === 2"
              class="successImg"
              style="color: #f15643;"
              >未通过</span
            >
            <span
              v-else-if="item.creditStatus === 4"
              class="successImg"
              style="color: #666666;"
              >未认证</span
            >
            <span
              class="span_font"
              :style="item.creditStatus !== 1 ? 'margin-left: 55px' : ''"
              >{{ item.comName }}</span
            >
          </li>
          <li class="select-item-created" @click="handleCreadedCom">
            <span> ＋ 创建企业</span>
          </li>
        </ul>
      </div>
    </van-dialog>
    <van-dialog
      v-model="qrCodeDia"
      title="提示"
      show-cancel-button
      :before-close="qrCodeConfirm"
      confirm-button-text="长按图片保存二维码"
      cancel-button-text="复制链接"
      cancel-button-color="#1676FF"
    >
      <div class="qrCodeDia">
        <div>企业认证后才可以签署<br />邀请系统管理员/法人进行认证</div>
        <img id="qrcodeImg" style="width: 150px;" :src="QRCodeImg" alt="" />
        <div class="qrCodeDia-text">
          【{{ systemInfo.company }}】您的同事{{
            userInfo.name
          }}邀请您进行企业认证，请点击链接完成认证
        </div>
        <div class="qrCodeDia-text">{{ QRCodeUrl }}</div>
      </div>
    </van-dialog>
  </div>
</template>
<script>
  import { mapState } from 'vuex'
  import { Dialog, Toast } from 'vant'
  import Api from '@/api/addSign'
  import CreditApi from '@/api/creadit'
  import QrCode from 'qrcode'

  export default {
    props: ['isPopup', 'isFormLogin'],
    data() {
      this.JOIN_COM = 'JOIN_COM'
      return {
        selectIdentityDia: false,
        selectComId: null,
        qrCodeDia: false,
        QRCodeUrl: null,
        QRCodeImg: null,
        fileStatus: null,
      }
    },
    computed: {
      ...mapState({
        userInfo: state => state.user.userInfo,
        myInfo: state => state.user.myInfo,
        partnerId: state => state.sign.partnerId,
        signMsg: state => state.sign.signMsg,
        systemInfo: state => state.system,
      }),
      isZdd() {
        return this.$store.getters['system/isZdd']
      },
    },
    watch: {
      // 'myInfo.companyInfoVoList': {
      //   handler(newV) {
      //     if (newV.length === 1) {
      //       this.onSelect(this.myInfo.companyInfoVoList[0].comId)
      //     }
      //   },
      //   immediate: true,
      // },
      isFormLogin: {
        handler(newV) {
          if (newV) {
            this.addSign()
          }
        },
        immediate: true,
      },
    },
    mounted() {
      this.fileStatus = this.$route.query.fileStatus
      // this.generateQrCode()
    },
    methods: {
      onSelect(comId) {
        this.selectComId = comId
      },
      onConfirm(action, done) {
        if (action === 'cancel') {
          done()
          return
        }
        if (!this.selectComId) {
          Toast('请选择公司身份')
          done(false)
        }
        done()
        this.addSign()
      },
      qrCodeConfirm(action, done) {
        if (action === 'cancel') {
          this.qrCodeCancel()
          done()
          return
        }
        done(false)
      },
      handleCreadedCom() {
        this.$store.commit('setComSignUrl', '/addSignNoConfirm')
        this.$router.push({
          path: 'createCompany',
        })
      },
      async addSign() {
        this.toast = Toast.loading({
          message: '加载中...',
          forbidClick: true,
          loadingType: 'spinner',
        })
        let res
        if (this.fileStatus === 8 || this.fileStatus === '8') {
          res = await Api.updateInvalidJoinSign({
            partnerId: this.partnerId,
            comId: this.selectComId,
            // type: 1,
          })
        } else {
          res = await Api.joinSign({
            partnerId: this.partnerId,
            comId: this.selectComId,
            // type: 1,
          })
        }
        await this.$store.dispatch('fetchUserInfo')
        const { msg, status, data } = res
        if (status === 200) {
          const { businessStatus } = data
          // 0 草稿 1未完成 2 已完成 3已作废
          if (businessStatus === 3) {
            this.$router.push({
              path: '/signComplete',
            })
            return
          }
          this.$router.push({
            path: '/signdetail',
            query: {
              businessId: data.businessId,
              fileStatus: this.fileStatus,
            },
          })
          return
        }
        // 请选择企业
        if (status === 207) {
          let comIdArr = this.myInfo.companyInfoVoList.map(item => {
            return item.comId
          })
          this.selectComId = comIdArr.shift()
          this.selectIdentityDia = true
          return
        }
        // 个人未实名
        if (status === 208) {
          // Dialog.alert({
          //   title: '提示',
          //   message: msg,
          //   confirmButtonText: '去认证',
          // }).then(() => {
          this.$router.push({
            path: 'signAuth',
            query: {
              url: '/addSignNoConfirm',
            },
          })
          // })
          return
        }
        // 企业未认证
        if (status === 205) {
          Dialog.alert({
            title: '提示',
            message: msg,
            messageAlign: 'left',
            confirmButtonText: '去认证',
            showCancelButton: '暂不认证',
          }).then(() => {
            // this.$router.push('/addSignError2')
            this.$store.commit('setComSignUrl', '/addSignNoConfirm')
            this.$router.push('AuthSubmit')
          })
          return
        }
        if (status === 209) {
          Dialog.alert({
            title: '提示',
            message: msg,
            confirmButtonText: '创建企业',
          }).then(() => {
            this.$store.commit('setComSignUrl', '/addSignNoConfirm')
            this.$router.push({
              path: 'createCompany',
            })
          })
          return
        }
        // 企业身份，必须认证且无权限
        if (status === 211) {
          console.log('211-必须要认证，无权限')
          this.generateQrCode()
          return
        }
        // 当前选择的身份不正确，清空 当前选择的 comId
        if (status === 212) {
          Dialog.alert({
            title: '提示',
            message: msg,
          }).then(() => {})

          this.selectComId = undefined
          return
        }
        // 手机号不一致
        if (status === 701) {
          Dialog.alert({
            title: '提示',
            message: msg,
            messageAlign: 'left',
            confirmButtonText: '更换账号登录',
            showCancelButton: '知道了',
          }).then(() => {
            this.$store.dispatch('logout')
            this.$router.push({
              path: '/login',
              query: {
                redirect: this.$route.fullPath,
              },
            })
          })
          return
        }
        Dialog.alert({
          title: '提示',
          message: msg,
        }).then(() => {})
        this.toast.clear()
      },
      // qrCodeConfirm() {
      //   console.log('保存二维码')
      //   const image = document.getElementById('qrcodeImg')
      //   const base64ToBlob = function(code) {
      //     const parts = code.split(';base64,')
      //     const contentType = parts[0].split(':')[1]
      //     const raw = window.atob(parts[1])
      //     const rawLength = raw.length
      //     const uInt8Array = new Uint8Array(rawLength)
      //     for (let i = 0; i < rawLength; ++i) {
      //       uInt8Array[i] = raw.charCodeAt(i)
      //     }
      //     return new Blob([uInt8Array], {
      //       type: contentType,
      //     })
      //   }
      //   const aLink = document.createElement('a')
      //   const blob = base64ToBlob(image.src)
      //   const evt = document.createEvent('HTMLEvents')
      //   evt.initEvent('click', true, true)
      //   aLink.download = 'qrcode.png'
      //   aLink.href = URL.createObjectURL(blob)
      //   aLink.click()
      // },
      qrCodeCancel() {
        const url = `【${this.systemInfo.company}】您的同事${this.userInfo.name}邀请您进行企业认证，请点击链接完成认证${this.QRCodeUrl}`
        this.$copyText(url)
        this.$toast({
          message: '复制成功',
        })
      },
      async generateQrCode() {
        const comId = this.selectComId
        const url = `${window.location.origin}/authSubmit?comId=${comId}`
        this.$nextTick(() => {
          this.QRCodeUrl = url
          QrCode.toDataURL(url, {}, (err, res) => {
            console.log('res', res)
            if (err) {
              console.error(err)
            } else {
              this.QRCodeImg = res
              this.qrCodeDia = true
            }
          })
        })
      },
    },
  }
</script>
<style lang="less">
  .qrCodeDia {
    text-align: center;
    font-size: 14px;
    padding: 16px;
    .qrCodeDia-text {
      text-align: left;
    }
  }
</style>
<style lang="less" scoped>
  .addSignNoConfirm {
    padding-top: 20px;
    .companyInfoVo-dialog {
      width: 80% !important;
      padding: 0 15px;
      border-radius: 4px;
    }
    .select-wrap {
      .select-title {
        width: 227px;
        height: 22px;
        font-size: 18px;
        font-weight: 400;
        text-align: center;
        color: #000;
        line-height: 22px;
        margin: 0 auto;
        margin-bottom: 30px;
        padding: 6px 15px 15px;
        margin-top: 15px;
      }
      .select-list {
        // max-height: 500px;
        height: 258px;
        overflow-y: auto;
        padding-bottom: 15px;
        .select-item {
          // padding: 8px;
          width: 100%;
          height: 44px;
          line-height: 44px;
          border: 1px solid #e2e4ea;
          padding: 0 12px;
          border-radius: 2px;
          margin: 0 auto;
          font-size: 14px;
          font-family: PingFangSC, PingFangSC-Medium;
          font-weight: 500;
          text-align: center;
          color: #111a34;
          margin-bottom: 16px;
          position: relative;
          display: flex;
          align-items: center;
          .successImg {
            position: absolute;
            font-size: 12px;
          }
          .Fe943d {
            color: #fe943d;
          }
          .span_font {
            margin-left: 24px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
        .select-item-active {
          // padding: 8px;
          width: 100%;
          height: 44px;
          line-height: 44px;
          padding: 0 12px;
          border: 1px solid #1676ff;
          border-radius: 2px;
          font-size: 14px;
          font-family: PingFangSC, PingFangSC-Medium;
          font-weight: 500;
          // color: #1676ff;
          display: flex;
          align-items: center;
          margin: 0 auto;
          &::after {
            flex-shrink: 0;
            content: '';
            width: 15px;
            height: 10px;
            margin-top: 3px;
            background: url('../../assets/imgs/ios2x_路径 2@2x.png') no-repeat
              center;
            background-size: 15px 10px;
            position: absolute;
            right: 12px;
          }
          margin-bottom: 16px;
          position: relative;
          .successImg {
            position: absolute;
            font-size: 12px;
          }
          .span_font {
            margin-left: 24px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
        .select-item-created {
          width: 100%;
          height: 36px;
          background: #1676ff;
          border-radius: 4px;
          font-weight: 500;
          color: #ffffff;
          font-size: 14px;
          line-height: 36px;
          border: none;
          display: flex;
          justify-content: center;
          // &::before {
          //   flex-shrink: 0;
          //   content: '';
          //   width: 12px;
          //   height: 12px;
          //   margin-right: 4px;
          //   margin-top: 2px;
          //   background: url('../../assets/imgs/\sealManage/add.png') no-repeat
          //     center;
          //   background-size: 12px 12px;
          // }
          // margin-bottom: 16px;
        }
      }
    }
    .content {
      padding: 0 24px;
      .title {
        font-size: 18px;
        font-weight: 500;
        text-align: left;
        color: #111a34;
        line-height: 25px;
        margin-bottom: 10px;
        padding-right: 45px;
        position: relative;
        width: 314px;
        span {
          width: 269px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2; //多行在这里修改数字即可
          /* autoprefixer: ignore next */
          -webkit-box-orient: vertical;
        }
        .logo {
          width: 46px;
          height: 46px;
          position: absolute;
          top: -10px;
          right: 10px;
        }
      }
      .initiator {
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 500;
        text-align: left;
        color: #677283;
        line-height: 20px;
        margin-bottom: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .footer {
      position: fixed;
      bottom: @safe-area;
      left: 0;
      width: 100%;
      height: 60px;
      background: rgba(255, 255, 255, 1);
      box-shadow: 0px -6px 10px 0px rgba(230, 234, 237, 0.3);
      padding-top: 10px;
      .btn {
        width: 327px;
        height: 44px;
        background: rgba(22, 118, 255, 1);
        box-shadow: 0px 6px 20px 0px rgba(78, 128, 245, 0.16);
        border-radius: 4px;
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(255, 255, 255, 1);
        line-height: 44px;
        text-align: center;
        margin: 0 auto;
        margin-bottom: 16px;
      }
      .btn2 {
        background: rgba(22, 118, 255, 0.2);
      }
      .des {
        height: 16px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(109, 119, 144, 1);
        line-height: 16px;
        text-align: center;
        span {
          color: rgba(22, 118, 255, 1);
        }
      }
    }
  }
</style>
