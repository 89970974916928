import { get, post, postStatus } from './axios'

export default {
  // 实名认证--根据token登录并获取相关信息
  getCreditTokenInfo(params) {
    return get('/user/credit/getCreditTokenInfo', params)
  },
  // 返回需要签署的文件图片
  getSignFile(params) {
    return get('/user/credit/getSignFile', params)
  },
  // 企业认证--获取企业认证状态
  getComAuthStatus(params) {
    return get('/user/credit/getComAuthStatus', params)
  },
  // 签署授权文件
  signFile(params) {
    return post('/user/credit/signFile', params)
  },
  // 企业认证--开始签署前法人校验
  validLegal(params) {
    return get('/user/credit/validLegal', params)
  },
  // 企业认证状态校验
  getComCreditInfo(params) {
    return get('/user/companyInfo/getComCreditInfo', params)
  },
  // 企业信息查询
  getComBaseInfo(params) {
    return get('/user/companyInfo/getComBaseInfo', params)
  },
  // 企业认证--创建企业
  createCom(params) {
    return get('/user/credit/createCom', params)
  },
  // 企业认证--获取企业认证信息
  getComAuthInfo(params) {
    return get('/user/credit/getComAuthInfo', params)
  },
  // 企业认证--选择线上线下
  updateComAuthType(params) {
    return get('/user/credit/updateComAuthType', params)
  },
  // 企业认证--行业列表
  getIndustry(params) {
    return get('/user/credit/getIndustry', params)
  },
  // 企业认证--营业执照ocr识别
  ocrBusinessPic(params) {
    return post('/user/credit/ocrBusinessPic', params)
  },
  // 企业认证--提交基本信息
  addComBasicInfo(params) {
    return postStatus('/user/credit/addComBasicInfo', params)
  },
  // 实名认证---获取免登录token
  getCreditToken(param) {
    return get('/user/credit/getCreditToken', param)
  },

  // 实名认证--腾讯H5人脸认证
  h5FaceLogin(param) {
    return post('/user/credit/h5FaceLogin', param)
  },
  // 实名认证--腾讯H5人脸回调接口
  updateH5FaceResult(param) {
    return post('/user/credit/updateH5FaceResult', param)
  },
  /**
   * 企业认证-企业列表
   * @param {*} param
   */
  getCompanyList(param) {
    return get('/user/credit/getCompanyList', param)
  },
  /**
   * 企业认证-是不是法人
   * @param {*} param
   */
  validLegalFourElements(param) {
    return get('/user/credit/validLegalFourElements', param)
  },
}
